import { lazy, useEffect } from 'react';
import { useRoutes, RouteObject, Navigate, useLocation } from 'react-router-dom';

const Home = lazy(() => import('~/pages/Home'));
const Products = lazy(() => import('~/pages/Products'));
const APIPartner = lazy(() => import('~/pages/APIpartner'));
const AboutUs = lazy(() => import('~/pages/AboutUs'));
const Register = lazy(() => import('~/pages/Register'));
const Terms = lazy(() => import('~/pages/Terms'));

const routes: Array<RouteObject & { title: string }> = [
  {
    path: '/',
    element: <Home />,
    title: '多会儿旅行 / Duohuir Travel',
  },
  {
    path: '/products',
    element: <Products />,
    title: '多会儿旅行 - 全球酒店预定介绍',
  },
  {
    path: '/APIpartner',
    element: <APIPartner />,
    title: '多会儿旅行分销API',
  },
  {
    path: '/aboutus',
    element: <AboutUs />,
    title: '关于多会儿旅行',
  },
  {
    path: '/Register',
    element: <Register />,
    title: '多会儿旅行 - 注册',
  },
  {
    path: '/TermsConditions',
    element: <Terms />,
    title: '多会儿旅行 - 服务协议',
  },
  {
    path: '*',
    element: <Navigate to="/" />,
    title: '多会儿旅行 / Duohuir Travel',
  },
];

export default function Routes() {
  const element = useRoutes(routes);
  const location = useLocation();

  useEffect(() => {
    document.title =
      routes.find(({ path }) => {
        if (path === '*') {
          return false;
        } else {
          return location.pathname.match(path || '');
        }
      })?.title || '多会儿旅行 / Duohuir Travel';
  }, [location.pathname]);

  return element;
}

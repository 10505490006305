import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum ButtonVariant {
  SOLID = 'solid',
  SOLID_YELLOW = 'solidYellow',
  OUTLINE = 'outline',
  TEXT = 'text',
}

const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'blue.50',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: 1.2,
    p: 4,
    backgroundColor: 'primary',
    borderColor: 'primary',
    borderWidth: '1px',
    borderRadius: '20px',
    _focus: {
      boxShadow: 'unset',
    },
  },
  variants: {
    [ButtonVariant.SOLID]: {
      bg: 'primary',
      color: 'blue.50',
      borderWidth: 0,
      _hover: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        _disabled: {
          bg: 'blue.100',
        },
      },
      _disabled: {
        bg: 'blue.100',
        color: 'fontColor',
      },
      _active: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        _disabled: {
          bg: 'blue.100',
          color: 'fontColor',
        },
      },
    },
    [ButtonVariant.SOLID_YELLOW]: {
      bg: 'yellow.500',
      color: 'fontColor',
      borderWidth: 0,
      _hover: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F2C501',
        _disabled: {
          bg: 'blue.100',
        },
      },
      _disabled: {
        bg: 'blue.100',
      },
      _active: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F2C501',
        _disabled: {
          bg: 'blue.100',
        },
      },
    },
    [ButtonVariant.OUTLINE]: {
      bg: 'transparent',
      color: 'primary',
      borderColor: 'primary',
      borderWidth: 1,
      rounded: 20,
      _hover: {
        bg: 'transparent',
        color: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        borderColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        _disabled: {
          bg: 'blue.100',
          color: 'blue.200',
          border: '1px solid #D7DFEE',
        },
      },
      _disabled: {
        bg: 'blue.100',
        color: 'blue.200',
        border: '1px solid #D7DFEE',
      },
      _active: {
        bg: 'transparent',
        color: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        borderColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2D6CDF',
        _disabled: {
          bg: 'blue.100',
          color: 'blue.200',
          border: '1px solid #D7DFEE',
        },
      },
    },
  },
};

export default Button;

import type { ComponentStyleConfig } from '@chakra-ui/react';

export enum HeadingVariant {
  MARK = 'mark',
}
const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
  },
  variants: {
    [HeadingVariant.MARK]: {
      color: 'fontColor',
      position: 'relative',
      _before: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        m: 'auto',
        zIndex: -1,
        content: '""',
        height: '40%',
        borderRadius: 10,
        backgroundColor: 'yellow.500',
      },
    },
  },
};

export default Heading;

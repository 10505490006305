import { Link as RouterLink } from 'react-router-dom';
import { Link, HStack } from '@chakra-ui/react';
import { LoginUrl } from '~/constants';
export default function AuthButtonGroup() {
  return (
    <HStack>
      <Link w="100px" as={RouterLink} to="/Register" color="primary" textAlign="center">
        免费注册
      </Link>
      <Link w="100px" href={LoginUrl} color="primary" textAlign="center">
        登入
      </Link>
    </HStack>
  );
}

import type { ComponentStyleConfig } from '@chakra-ui/react';

const Drawer: ComponentStyleConfig = {
  sizes: {
    '2xs': { dialog: { maxW: '2xs' } },
    '3xs': { dialog: { maxW: '3xs' } },
  },
};

export default Drawer;

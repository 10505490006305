import { Colors } from '@chakra-ui/react';

const colors: Colors = {
  primary: '#2D6CDF',

  fontColor: '#002C5E',
  backgroundColor: '#F2F6FF',

  yellow: {
    500: '#F2C501',
  },

  blue: {
    50: '#FAFAFA',
    100: '#F2F6FF',
    200: '#D7DFEE',
    300: '#DAE5F9',
    400: '#97AAC9',
    500: '#8991C5',
    600: '#6482A3',
    700: '#002C5E',
  },

  checkbox: {
    500: '#002C5E',
  },

  switch: {
    200: '#B9C1CF',
    500: '#2D6CDF',
  },

  red: {
    500: '#FE5D6D',
  },
};

export default colors;

import {
  Image,
  HStack,
  Link,
  Center,
  Icon,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { HiMenuAlt1 } from 'react-icons/hi';
import AuthButtonGroup from '~/components/AuthButtonGroup';

import HeaderLogo from '~/svgs/headerLogo.svg';

const navItems = [
  {
    title: '首页',
    path: '/',
  },
  {
    title: '产品资源',
    path: '/products',
  },
  {
    title: 'API/技术合作',
    path: '/APIpartner',
  },
  // {
  //   title: '供应商合作',
  //   path: '/supplier',
  // },
  {
    title: '关于我们',
    path: '/aboutus',
  },
];

const handleRouteMatch = (currentPath: string, itemPath: string) => {
  if (!currentPath || !itemPath) return false;
  if (itemPath === navItems[0].path) {
    return currentPath === itemPath;
  }
  return currentPath.match(itemPath);
};

export default function Header({ isWithNav = true }: { isWithNav?: boolean }) {
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  console.log(location.pathname);
  return isLargerThan480 ? (
    <Center minW="100%" bg="white" filter="drop-shadow(0px 4px 20px rgba(188, 204, 233, 0.4))">
      <HStack minW="1198px" spacing="90px" justifyContent="space-between" mt="19px" mb="10px">
        <Link as={RouterLink} to="/">
          <Image src={HeaderLogo} alt="多会儿旅行" />
        </Link>
        {isWithNav && (
          <HStack justifyContent="space-between" flex={1}>
            {navItems.map(({ title, path }) => (
              <Link
                key={path}
                as={RouterLink}
                to={path}
                color={handleRouteMatch(location.pathname, path) ? 'primary' : 'blue.500'}
                fontWeight={handleRouteMatch(location.pathname, path) ? '700' : '400'}
                _hover={{ color: 'primary' }}
                borderTop="3px solid transparent"
                borderBottom="3px solid"
                borderBottomColor={
                  handleRouteMatch(location.pathname, path) ? 'primary' : 'transparent'
                }
              >
                {title}
              </Link>
            ))}
          </HStack>
        )}
        <AuthButtonGroup />
      </HStack>
    </Center>
  ) : (
    <>
      <HStack
        bg="white"
        alignSelf="stretch"
        filter="drop-shadow(0px 4px 20px rgba(188, 204, 233, 0.4))"
        justifyContent="space-between"
        pt="18px"
        pb="24px"
        px={4}
      >
        <Link as={RouterLink} to="/">
          <Image src={HeaderLogo} alt="多会儿旅行" h="40px" />
        </Link>
        {isWithNav && <Icon as={HiMenuAlt1} boxSize="24px" onClick={onOpen} />}
      </HStack>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <VStack h="100%" pt={6} pb={12}>
              <VStack spacing={6} flex={1}>
                <Image src={HeaderLogo} alt="多会儿旅行" />
                {navItems.map(({ title, path }) => (
                  <Link
                    key={path}
                    as={RouterLink}
                    to={path}
                    color={handleRouteMatch(location.pathname, path) ? 'primary' : 'blue.500'}
                    fontWeight={handleRouteMatch(location.pathname, path) ? '700' : '400'}
                    _hover={{ color: 'primary' }}
                    borderTop="3px solid transparent"
                    borderBottom="3px solid"
                    borderBottomColor={
                      handleRouteMatch(location.pathname, path) ? 'primary' : 'transparent'
                    }
                  >
                    {title}
                  </Link>
                ))}
              </VStack>
              <AuthButtonGroup />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

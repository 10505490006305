import type { ComponentStyleConfig } from '@chakra-ui/react';

const Switch: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'switch',
  },
  baseStyle: {
    track: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};

export default Switch;

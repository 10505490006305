import type { ComponentStyleConfig } from '@chakra-ui/react';

const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      boxShadow: '0px 2.82028px 11.2811px rgba(131, 130, 251, 0.55)',
      bg: 'white',
      py: 6,
      rounded: 14,
    },
    header: {
      color: 'fontColor',
      fontWeight: '700',
      fontSize: '28px',
      textAlign: 'center',
    },
    footer: {
      justifyContent: 'center',
    },
  },
};

export default Modal;

import { ComponentStyleConfig } from '@chakra-ui/react';

export enum InputVariants {
  OUTLINE = 'outline',
}

const Input: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
  },
  variants: {
    [InputVariants.OUTLINE]: {
      p: 4,
      field: {
        color: 'fontColor',
        fontSize: '16px',
        borderRadius: 32,
        borderWidth: 2,
        borderColor: 'blue.600',
        _focus: {
          borderWidth: 2,
        },
        _hover: {
          borderColor: 'blue.600',
        },
        _placeholder: {
          color: 'blue.200',
          fontSize: '16px',
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary',
  },
};
export default Input;

import type { ComponentStyleConfig } from '@chakra-ui/react';

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
    fontWeight: '400',
    lineHeight: 0.2,
    letterSpacing: 0.008,
    mb: 0,
  },
};

export default FormLabel;

import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'checkbox',
  },
  baseStyle: {
    label: {
      color: 'fontColor',
      fontWeight: '400',
      lineHeight: 1.2,
      letterSpacing: 0.008,
    },
    control: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: ['14px', '16px'],
      },
    },
  },
};

export default Checkbox;

import { ComponentStyleConfig } from '@chakra-ui/react';
import Button from './Button';
import Text from './Text';
import Heading from './Heading';
import Input from './Input';
import Drawer from './Drawer';
import Checkbox from './Checkbox';
import List from './List';
import FormLabel from './FormLabel';
import Switch from './Switch';
import Modal from './Modal';

const components: Record<string, ComponentStyleConfig> = {
  Input,
  Button,
  Text,
  Heading,
  Drawer,
  Checkbox,
  List,
  FormLabel,
  Switch,
  Modal,
};

export default components;

import type { ComponentStyleConfig } from '@chakra-ui/react';

const List: ComponentStyleConfig = {
  baseStyle: {
    item: { color: 'fontColor', lineHeight: 1.2, letterSpacing: 0.008, my: 1, fontSize: [14, 16] },
  },
  variants: {},
};

export default List;

import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Box, ChakraProvider } from '@chakra-ui/react';
import theme from '~/theme';
import Routes from '~/routes';
import Header from './components/Header';

function App() {
  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Suspense
            fallback={
              <Box minW="1200px">
                <Header />
              </Box>
            }
          >
            <Routes />
          </Suspense>
        </BrowserRouter>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;

import type { ComponentStyleConfig } from '@chakra-ui/react';

export enum TextVariant {
  MARK = 'mark',
}

const Text: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
    lineHeight: 1.2,
  },
  variants: {
    [TextVariant.MARK]: {
      color: 'fontColor',
      position: 'relative',
      _before: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        m: 'auto',
        zIndex: -1,
        content: '""',
        height: '40%',
        borderRadius: 10,
        backgroundColor: 'yellow.500',
      },
    },
  },
};

export default Text;
